<template>
  <section class="promise">
    <v-container>
      <v-row>
        <v-col 
          v-for="(info, i) in tabs"
          :key="i"
          cols="6"
          sm="6"
          md="6"
          lg="3"
          class="d-flex flex-column align-center"
        >
          <img :src="info.img" alt="icon" width="36px" height="36px" />
          <span class="promise__text">{{ info.text }}</span>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'Promise',

  data() {
    return {
    }
  },

  computed: {
    tabs() {
      return [
        { img: require('@/assets/img/svg/hearts.svg'), text: this.$t('100_authentic') }, 
        { img: require('@/assets/img/svg/back.svg'), text: this.$t('7day_returns') },
        { img: require('@/assets/img/svg/car.svg'), text: this.$t('shipping_discounts') },
        { img: require('@/assets/img/svg/card.svg'), text: this.$t('safe_payment') },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .promise {
    background: #95ACFF;
    &__text {
      margin-top: 2px;
      color: #fff;
      font-size: 13px;
      font-weight: 700;
    }

  }
</style>
